export default () => (
    <div className="absolute bottom-0 w-full h-8 py-1 px-2 flex items-baseline justify-center bg-gray-900 bg-opacity-75 text-gray-100">
        <div className="text-xs">
        &copy; 2021 Felix Köcher, All Rights Reserved
        </div>
        <Spacer/>
        <Link name="Impressum" target="/impressum" sameSite />
    </div>
)

const Link = ({ name, target, sameSite = false }) => sameSite ? (
    <a className="text-s hover:underline" href={target}>{name}</a>
) : (
    <a className="text-s hover:underline" href={target} target="_blank" rel="noreferrer">{name}</a>
)

const Spacer = () => (
    <div className="px-1 select-none">|</div>
)
