import Parallax from './parallax'
import Footer from './footer'

export default ({ children }) => (
    <div className="min-h-screen relative">
        <Parallax />
        <main className="pb-12 pt-4">
            {children}
        </main>
        <Footer />
    </div>
)
