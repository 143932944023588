import { useEffect, useRef, useState } from "react";

function getScrollPercent() {
  let h = document.documentElement;
  let b = document.body;
  let st = 'scrollTop';
  let sh = 'scrollHeight';
  return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
}

const Background = () => {
  let latestScrollPercent = 0;
  let ticking = false;

  const ref = useRef();
  const [scroll, setScroll] = useState(0);
  const [parallaxHeight, setParallaxHeight] = useState('100vh');

  const updateScrolling = () => {
    ticking = false;

    let distanceToScroll = ref.current.clientHeight - document.documentElement.clientHeight;
    if (distanceToScroll < 0) distanceToScroll = 0;
    setScroll(- latestScrollPercent * distanceToScroll / 100);

    let height = getParallaxHeight();
    setParallaxHeight(height)
  };

  const requestTick = () => {
    if (!ticking) requestAnimationFrame(updateScrolling);
    ticking = true;
  };

  const handleScroll = () => {
    latestScrollPercent = getScrollPercent();
    requestTick();
  };

  const getParallaxHeight = () => {
    // Is overflowing
    if (document.body.scrollHeight > document.body.clientHeight) return `${document.body.scrollHeight}px`;
    // Not overflowing
    return '100vh';
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener('resize', handleScroll);
    window.addEventListener('onload', handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('onload', handleScroll);
    }
  }, []);

  return <div ref={ref} className="fixed w-screen -z-10 top-0" style={{ minHeight: parallaxHeight, transform: `translate(0px, ${scroll}px)` }}>
    <img ref={ref} src="/images/website_background_1.jpg"
      style={{ minHeight: "inherit" }}
      className="relative object-cover"
    />
    <div className="hidden lg:block ">
      <picture>
        <source srcSet="/images/website_background_2_cut.png" media="(min-width: 3841px)" />
        <source srcSet="/images/website_background_2_cut_4k.png" media="(min-width: 1921px)" />
        <source srcSet="/images/website_background_2_cut_2k.png" media="(min-width: 1024px)" />
        <img src="" loading='lazy' className="lg:w-2/3 object-contain absolute bottom-0" style={{ left: '-3vw' }} />
      </picture>
    </div>
  </div>
};

export default () => (
  <div>
    <Background />
  </div>
)
